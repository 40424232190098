import React from 'react';

// import Cookies from "js-cookie";
// import $ from "jquery";

import Layout from '../components/layout';
import SEO from "../components/seo";

import FormField from '../components/utilities/FormField';

import './reports.scss';

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />        
        <article className={`${pageContext.slug} reports-template`}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 dangerouslySetInnerHTML={{ __html: pageContext.title}} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-9" dangerouslySetInnerHTML={{ __html: pageContext.acf.display_content}} />
                    <div className="form-wrapper col-xs-12 col-md-3">
                        <p className="nobold small">
                            <br />
                                Please <strong>complete this form</strong> to download a PDF report.
                        </p>
                        <p className="req nobold small">* Required fields</p>
                        <form id="reportForm" name="metrixform" method="POST" action="https://metrix.meritmile.com/email.aspx">
                            <FormField fieldID={"name"} type={"text"}  name={"name"} label={"Name"}/>
                            <FormField fieldID={"company"} type={"text"}  name={"company"} label={"Company"}/>
                            <FormField fieldID={"email"} type={"email"}  name={"email"} label={"Email"}/>
                            <FormField fieldID={"phone"}  type={"tel"} name={"phone"} label={"Daytime Phone"}/>
                            <button type="submit" className="btn btn-black">DOWNLOAD NOW</button>
                            <div className="form-group">
                                <input type="hidden" name="referrer" value="right here" />
                                <input type="hidden" name="auth" value={pageContext.acf.form_field_id} />
                                <input type="text" className="d-none hu" id="human-check" name="human-check" />
                                <div className="feedback"></div>
                                <div className="fix"></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </article>
    </Layout>
)